import { createSettingsParams, SettingsParamType } from '@wix/tpa-settings';
import calendarSettingsParams, {
  ISettingsParams,
} from '../BookingCalendar/settingsParams';
import { AccessibilityHtmlTags } from '../../utils/accessibility/constants';

type CalendarWidgetSettingsOverride = Pick<
  ISettingsParams,
  'headerTitleHtmlTag' | 'dateAndTimeSectionHtmlTag'
>;

const calendarWidgetSettingsParams =
  createSettingsParams<CalendarWidgetSettingsOverride>({
    headerTitleHtmlTag: {
      type: SettingsParamType.String,
      getDefaultValue: () => AccessibilityHtmlTags.SecondaryHeading,
    },
    dateAndTimeSectionHtmlTag: {
      type: SettingsParamType.String,
      getDefaultValue: () => AccessibilityHtmlTags.ThirdHeading,
    },
  });

export default {
  ...calendarSettingsParams,
  ...calendarWidgetSettingsParams,
};
